import * as React from "react";
import { useCallback } from "react";
import SearchableMultiSelect from "../Forms/Widgets/SearchableMultiSelect";
import SearchableSelect from "../Forms/Widgets/SearchableSelect";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FormLabel from "react-bootstrap/FormLabel";
import isEqual from "react-fast-compare";

function valuesFromPayload(valueKey, labelKey, payload) {
  const rs = (payload[valueKey] || "").split(",");
  const ns = (payload[labelKey] || "").split(",");
  return rs.filter((f) => f !== "").map((r, i) => ({ id: r, name: ns[i] }));
}

export default function ExportScopeBuilder({ payload, setPayload }) {
  const updatePayload = useCallback(
    (v) => {
      const p = { ...payload, ...v };
      if (!isEqual(p, payload)) {
        setPayload(p);
      }
    },
    [payload, setPayload]
  );

  const updateNamedPairs = useCallback(
    (v, valKey, labelKey) => {
      const vals = v.map((r) => r.id).join(",");
      const keys = v.map((r) => r.name).join(",");
      updatePayload({ [valKey]: vals, [labelKey]: keys });
    },
    [updatePayload]
  );

  const setAccount = useCallback(
    (v) => updateNamedPairs([v], "account", "accountName"),
    [updateNamedPairs]
  );
  const setLink = useCallback((v) => updateNamedPairs([v], "link", "linkName"), [updateNamedPairs]);
  const setStudy = useCallback(
    (v) => updateNamedPairs([v], "study", "studyName"),
    [updateNamedPairs]
  );
  const setTree = useCallback((e) => updatePayload({ tree: e.target.checked }), [updatePayload]);
  const setRespondents = useCallback(
    (v) => updateNamedPairs(v, "respondents", "respondentNames"),
    [updateNamedPairs]
  );

  const setReportviews = useCallback(
    (v) => updateNamedPairs(v, "reportviews", "reportviewNames"),
    [updateNamedPairs]
  );

  const setAssessments = useCallback(
    (v) => updateNamedPairs(v, "assessments", "assessmentNames"),
    [updateNamedPairs]
  );

  const setInstruments = useCallback(
    (v) => updateNamedPairs(v, "instruments", "instrumentNames"),
    [updateNamedPairs]
  );

  const setStartDate = useCallback(
    (v) => updatePayload({ startDate: v.currentTarget.value }),
    [updatePayload]
  );

  const setEndDate = useCallback(
    (v) => updatePayload({ endDate: v.currentTarget.value }),
    [updatePayload]
  );

  const mapAccounts = useCallback(
    (data) =>
      data.accounts
        .concat(data.folders)
        .map((d) => ({ id: d["login"], name: `${d["login"]} - ${d["name"]}` })),
    []
  );

  const mapLinks = useCallback(
    (data) => data.links.map((d) => ({ id: d["login"], name: `${d["login"]} - ${d["name"]}` })),
    []
  );

  const mapRespondents = useCallback(
    (data) =>
      data.respondents.map((d) => ({
        id: d["id"],
        name: `${d["passwd"]} - ${d["firstName"]} ${d["lastName"]}`,
      })),
    []
  );

  const mapGeneric = useCallback(
    (data) => data.map((d) => ({ id: d.id, name: `${d.id} - ${d.name}` })),
    []
  );

  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col xs={2}>
          <FormLabel>Account:</FormLabel>
        </Col>
        <Col xs={5}>
          <SearchableSelect
            includeBlank={true}
            url="/show.json"
            params={{ resource: "account_or_folder" }}
            map={mapAccounts}
            name="account"
            value={{ id: payload.account, name: payload.accountName }}
            onItemSelect={setAccount}
            delayMs={150}
          />
          {payload.account && (
            <label className="form-text text-muted">
              <input type="checkbox" checked={payload.tree} name="tree" onChange={setTree} /> Export
              data from descendants
            </label>
          )}
        </Col>
        <Col xs={1}>
          <FormLabel>Link:</FormLabel>
        </Col>
        <Col>
          <SearchableSelect
            url="/show.json"
            includeBlank={true}
            params={{ resource: "link", accountId: payload.account }}
            map={mapLinks}
            name="link"
            value={{ id: payload.link, name: payload.linkName }}
            onItemSelect={setLink}
            delayMs={150}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2}>
          <FormLabel>Start Date:</FormLabel>
        </Col>
        <Col xs={4}>
          <input
            type="date"
            value={payload.startDate}
            onChange={setStartDate}
            className="form-control"
          />
        </Col>
        <Col xs={2}>
          <FormLabel>End Date:</FormLabel>
        </Col>
        <Col xs={4}>
          <input
            type="date"
            value={payload.endDate}
            onChange={setEndDate}
            className="form-control"
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2}>
          <FormLabel>Respondents:</FormLabel>
        </Col>
        <Col>
          <SearchableMultiSelect
            url="/show.json"
            params={{ resource: "respondent", linkId: payload.link }}
            map={mapRespondents}
            name="respondents"
            values={valuesFromPayload("respondents", "respondentNames", payload)}
            onItemsChanged={setRespondents}
            delayMs={150}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2}>
          <FormLabel>Reportviews:</FormLabel>
        </Col>
        <Col>
          <SearchableMultiSelect
            url="/reportviews.json"
            map={mapGeneric}
            name="reportviews"
            values={valuesFromPayload("reportviews", "reportviewNames", payload)}
            onItemsChanged={setReportviews}
            delayMs={150}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2}>
          <FormLabel>Assessments:</FormLabel>
        </Col>
        <Col>
          <SearchableMultiSelect
            url="/assessments.json"
            map={mapGeneric}
            name="assessments"
            values={valuesFromPayload("assessments", "assessmentNames", payload)}
            onItemsChanged={setAssessments}
            delayMs={150}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2}>
          <FormLabel>Instruments:</FormLabel>
        </Col>
        <Col>
          <SearchableMultiSelect
            url="/instruments.json"
            map={mapGeneric}
            name="instruments"
            values={valuesFromPayload("instruments", "instrumentNames", payload)}
            onItemsChanged={setInstruments}
            delayMs={150}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2}>
          <FormLabel>Studies:</FormLabel>
        </Col>
        <Col>
          <SearchableSelect
            url="/studies.json"
            includeBlank={true}
            name="study"
            value={{ id: payload.study, name: payload.studyName }}
            onItemSelect={setStudy}
            delayMs={150}
            map={mapGeneric}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
}
